import React, {useState} from "react";
import UserPool from "../UserPool";
import {CognitoUserAttribute} from 'amazon-cognito-identity-js'
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
const Signup = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const onSubmit = (event) => {
        event.preventDefault();

        const attributes = [new CognitoUserAttribute({"Name": "name", "Value": name})]
        UserPool.signUp(
            email, password, attributes, null, (err, data) => {
                if (err) {
                    console.error(err)
                }
                console.log(data)
            }
        )
        //Then redirect to page with thankyou and information of what happens next
    }

    return (
        <div style={{
            display:"flex",
            alignContent:"center",
            alignItems:"center",
            justifyContent:"center",
            overflow:"hidden",
            height:"calc(100% - 50px)"

        }}>

            <div style={{ display:"flex", flexDirection:"column", justifyContent:"space-between", padding:"30px", borderRadius:"10px", backgroundColor:"rgba(0,0,0,0.8)", width:"300px", height:"400px"}}>

                <Typography variant="h4" style={{color:"white", fontFamily: 'Fjalla One'}}>Create Account</Typography>

                <TextField
                    label={"Name"}
                    value={name}
                    onChange={(event)=>{setName(event.target.value)}}
                />

                <TextField
                    label={"Email"}
                    value={email}
                    onChange={(event)=>{setEmail(event.target.value)}}
                />

                <TextField
                    label={"Password"}
                    value={password}
                    onChange={(event)=>{setPassword(event.target.value)}}
                />

                <Button onClick={onSubmit}>Create</Button>

            </div>
        </div>
    )
}

export default Signup