import React, {useState, useContext, useEffect} from "react";
import {AccountContext} from "./Account"
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import '../styles/Widget.css';
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import '@fontsource/fjalla-one';
import {redirect, useNavigate} from "react-router-dom";
import '../styles/App.css';

const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const {authenticate} = useContext(AccountContext)
    const nav = useNavigate();

    const onSubmit = (event) => {
        event.preventDefault();
        authenticate(email, password)
            .then(data=>{
                console.log("Logged in", data)
                nav("/")
                nav(0)
            })
            .catch(err => {
                console.error("Login failed", err)
            })
    }

    return (
        <div style={{
            display:"flex",
            alignContent:"center",
            alignItems:"center",
            justifyContent:"center",
            overflow:"hidden",
            height:"calc(100% - 50px)"

        }}>

            <div style={{ display:"flex", flexDirection:"column", justifyContent:"space-between", padding:"30px", borderRadius:"10px", backgroundColor:"rgba(0,0,0,0.8)", width:"300px", height:"400px"}}>

                <Typography variant="h4" style={{color:"white", fontFamily: 'Fjalla One'}}>Sign in</Typography>

                <TextField
                    label={"Email"}
                    value={email}
                    onChange={(event)=>{setEmail(event.target.value)}}
                />

                <TextField
                    label={"Password"}
                    value={password}
                    onChange={(event)=>{setPassword(event.target.value)}}
                />

                <Button onClick={onSubmit}>Sign in</Button>



            </div>
        </div>
    )
}

export default Login