import '../styles/Widget.css';
import React, {useState, useMemo, useRef, useEffect} from "react";
import { MapContainer, TileLayer, Marker, useMapEvent} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import {timezones} from "../constants/Timezones";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import {Link, useNavigate} from "react-router-dom";
import Constants from "../constants/Constants";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux'
import useApi from "../hooks/useApi"
import { v4 as uuidv4 } from 'uuid';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: iconRetina,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
});

const center = [53.73, 10.977]
const zoom = 5


function EventHandler(props) {
    const dispatch = useDispatch();

    const map = useMapEvent('click', (click) => {
        const clickLatitude = click.latlng.lat
        const clickLongitude = click.latlng.lng
        props.updatePin([clickLatitude, clickLongitude])
        console.log("here", clickLatitude, clickLongitude)
        dispatch({type: "pin", payload: [clickLatitude, clickLongitude]})
    })
    return null
}


export function AddLocation( props ) {
    const [map, setMap] = useState(null)
    const [pinLocation, setPinLocation] = useState(center)
    const [label, setLabel] = useState()
    const [timezone, setTimezone] = useState()
    const dispatch = useDispatch();
    const api = useApi(props.user);
    const navigate = useNavigate();

    useEffect(() => {
        // Component Mount
        dispatch({type:Constants.SET_CURRENT_LOCATION, payload: {name: undefined, lat: undefined, lon: undefined, tz: undefined}})
    }, []);

    console.log("pin at ", pinLocation)
    const displayMap = useMemo(
        () => (
            <MapContainer
                style={{width: "100%", top: "55px", height: "calc(100vh - 55px)"}}
                center={center}
                zoom={zoom}
                scrollWheelZoom={true}
                ref={setMap}
            >
                <EventHandler updatePin={setPinLocation}/>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker draggable={false} position={pinLocation}></Marker>

            </MapContainer>
        ),
        [pinLocation],
    )

    const submitNewLocation = (location) => {
        location.username = props.user.session.idToken.payload["cognito:username"]
        location.id = uuidv4()
        api.post("addlocation", {
            username: location.username,
            location: location }
        )
            .then( (response)=>{
                console.log("Location add success", response)

                dispatch({
                    type: Constants.SET_LOCATIONS,
                    payload: response.data.body
                })

                dispatch({
                    type: Constants.SET_CURRENT_LOCATION,
                    payload: location
                })
            })
            .catch((error)=>{
                console.error("Location add failed", error)
            })
    }

    return (
        <div style={{}}>
            <div style={{zIndex:"99"}}>
                {displayMap}
            </div>

            <div style={{width: "684px", left: "calc(50vw - 342px)", position: "absolute", bottom: "10vh", zIndex:"1000", display:"flex", justifyContent:"center"}}>
                <Paper sx={{width:"100%"}}>
                    <Grid container>
                        <Grid item>
                            <TextField
                                placeholder="Location name"
                                size="large"
                                id="outlined-basic"
                                label="Location"
                                variant="filled"
                                sx={{ width: 300, fontSize:"16px"}}
                                inputProps={{style: {fontSize: 24}}}
                                onChange={(event)=>{
                                    console.log("input is", event.target.value, label)
                                    setLabel(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={timezones}
                                size="large"
                                sx={{ width: 300}}
                                onChange={(event, value, reason)=>{
                                    setTimezone(value)
                                }}
                                renderInput={
                                    (params) => {
                                        return(
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                size="large"
                                                label="Timezone"
                                                InputProps={{ ...params.InputProps, style: { fontSize: 24 } }}
                                            />
                                        )
                                    }
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title="Add this location">
                                <Button size="large"  component={Link} to="/" type="button" sx={{height:"100%", width:"80px"}} onClick={
                                    ()=>{
                                        submitNewLocation(
                                            {
                                                "name": label,
                                                "lat": pinLocation[0],
                                                "lon": pinLocation[1],
                                                "tz": timezone
                                            }
                                        )
                                    }
                                }>
                                    Add
                                </Button>

                            </Tooltip>
                        </Grid>
                    </Grid>

                </Paper>
            </div>
        </div>
    )
}
