import '../styles/Widget.css';
import React, { useEffect, useState, Fragment } from "react";
import Spreadsheet from "react-spreadsheet";


export default function CustomDataEntry( props ) {
    const [data, setData] = useState([])
    return (
        <div>

            <Spreadsheet data={data} style={{width:"500px"}}/>;

        </div>
    )
}
