import '../styles/Widget.css';


export default function WeatherAlert( {key} ) {
  return (
    <div className="Widget">
      WeatherAlert
    </div>
  )
}
