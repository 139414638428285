import '../styles/Widget.css';
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box';
import { Measurement, MeasurementGroup } from './Measurement'
import Typography from "@mui/material/Typography";
import Moment from 'react-moment';
import { MapContainer, TileLayer, Marker, useMapEvent} from 'react-leaflet'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

//CSS and marker image fix for Leaflet map
import "leaflet/dist/leaflet.css";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";
import React, {useEffect, useMemo, useState} from "react";
import Constants from "../constants/Constants";

import suntimes from "../functions/suntimes"

L.Icon.Default.mergeOptions({
    iconRetinaUrl: iconRetina,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
});

export default function CurrentWeather( {key} ) {
    const data = useSelector(state => state.currentWeather)
    const location = useSelector(state=>state.currentLocation)
    const [map, setMap] = useState(null)
    const zoom = 6

    useEffect(() => {
        // Set location changed
        if(map&&location){
            map.setView(location, zoom)
        }
    }, [location]);

    console.log("Render CurrentWeather", "location=", location, "data=", data)

    if(!data || Object.keys(data).length <= 2 || ! location.name){
        return(
            <></>
        )
    }

    let sun = suntimes(location.lat, location.lon, -(new Date()).getTimezoneOffset()/60)
    return (

        <Box className="Widget" sx={{paddingTop:"50px", display: 'flex', flexDirection:"column"}}>
            {/**/}
            <MapContainer
                style={{width: "360px", height: "360px" }}
                center={[location.lat, location.lon]}
                zoom={zoom}
                ref={setMap}
                zoomControl={true}
                dragging={false}
                doubleClickZoom={false}
                scrollWheelZoom={true}
                attributionControl={false}
                zoomControl={false}>
                >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker draggable={false} position={[location.lat, location.lon]}></Marker>

            </MapContainer>
            <Typography variant="caption">
                Updated: <Moment>{data["time"]}</Moment>
            </Typography>

            <Grid
                direction="row"
                alignItems="space-between"
                justifyContent="space-between"
                container
                columns={2}
                spacing={1}
                style={{width:"100%", padding:"10px"}}
            >
                <Grid item xs={2}>
                    <Card variant="plain" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>

                        <Card variant="plain" sx={{margin: "5px", padding: "5px"}}>
                            <Typography variant="h5" color="#ffd740">
                                {sun.rise}
                            </Typography>
                        </Card>

                        <Card variant="plain" sx={{margin: "5px", padding: "5px"}}>
                            <Typography variant="caption" color="#ffd740">

                                Sunrise / Sunset
                            </Typography>
                        </Card>

                        <Card variant="plain" sx={{margin: "5px", padding: "5px"}}>
                            <Typography variant="h5" color="#ffd740">


                                {sun.set}
                            </Typography>
                        </Card>
                    </Card>


                </Grid>

                <Grid item>
                    <MeasurementGroup label={"Temperature"}>
                        <Measurement
                            value={data["temperature_2m"]}
                            unit={data["units"]["temperature_2m"]}
                        />
                        <Measurement
                            value={data["apparent_temperature"]}
                            unit={data["units"]["apparent_temperature"]}
                            memo="Feels like"
                        />
                    </MeasurementGroup>
                </Grid>
                <Grid item>
                    <MeasurementGroup label="Precipitation">
                        <Measurement
                            value={data["rain"]}
                            unit={data["units"]["rain"]}
                            memo="Rain"
                        />
                        <Measurement
                            value={data["showers"]}
                            unit={data["units"]["showers"]}
                            memo="Showers"
                        />
                        <Measurement
                            value={data["snowfall"]}
                            unit={data["units"]["snowfall"]}
                            memo="Snowfall"
                        />
                    </MeasurementGroup>
                </Grid>
                <Grid item>

                    <MeasurementGroup
                        label="Soil Temperature"
                    >
                        <Measurement
                            unit={data["units"]["soil_temperature_0cm"]}
                            value={data["soil_temperature_0cm"]}
                            memo="0cm"
                        />
                        <Measurement
                            unit={data["units"]["soil_temperature_6cm"]}
                            value={data["soil_temperature_6cm"]}
                            memo="6cm"
                        />
                        <Measurement
                            unit={data["units"]["soil_temperature_18cm"]}
                            value={data["soil_temperature_18cm"]}
                            memo="18cm"
                        />
                        <Measurement
                            unit={data["units"]["soil_temperature_54cm"]}
                            value={data["soil_temperature_54cm"]}
                            memo="54cm"
                        />
                    </MeasurementGroup>
                </Grid><Grid item>

                <MeasurementGroup
                    label="Clouds"
                >
                    <Measurement
                        unit={data["units"]["cloud_cover"]}
                        value={data["cloud_cover"]}
                        memo="Total"
                    />
                    <Measurement
                        unit={data["units"]["cloud_cover_high"]}
                        value={data["cloud_cover_high"]}
                        memo="High"
                    />

                    <Measurement
                        unit={data["units"]["cloud_cover_mid"]}
                        value={data["cloud_cover_mid"]}
                        memo="Mid"
                    />

                    <Measurement
                        unit={data["units"]["cloud_cover_low"]}
                        value={data["cloud_cover_low"]}
                        memo="Low"
                    />
                </MeasurementGroup>
            </Grid>

            </Grid>
        </Box>


    )
}
