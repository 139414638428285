 import './styles/App.css';
import ApplicationGrid from './components/Layout.js';
import React, {useContext, useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useInterval } from './hooks/useInterval'
import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Landingpage from "./components/Landingpage";
import DesktopLayout from './components/DesktopLayout.js'
import { amber, deepOrange, grey } from '@mui/material/colors';
import {Account, AccountContext} from "./components/Account"
import Status from "./components/Status"
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Navigate,
    useLocation
} from "react-router-dom";

const Constants = require('./constants/Constants')

const LoginRouter = () => {
    const { getSession, logout } = useContext(AccountContext)
    const [session, setSession] = useState(null)
    const [status, setStatus] = useState(undefined)


    useEffect(() => {
        console.log("getting session ...")
        getSession()
            .then(session=>{
                console.log(session)
                setStatus(true)
                setSession(session)
            })
            .catch(err=>{
                console.log("not logged in")
                setStatus(false)
                setSession(null)

            })
    }, []);

    console.log("render LoginRouter")

    const sessionLogout = () => {
        logout()
        setStatus(false)
        setSession(null)
    }

    if ( status===undefined ){
        return (
            <></>
        )
    }else if ( status === true ){
        return (
            <DesktopLayout user={{session, sessionLogout}}/>
        )
    }else {
        return (
            <Landingpage/>
        )
    }

}

function App() {

    //console.log("Render App")
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    const dispatch = useDispatch();
    let currentLocation = useSelector(state => state.currentLocation)

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);



    const getDesignTokens = (mode) => ({
        palette: {
            mode,
            action:
                {
                    "active": "#ffa000",
                    "disabled": "#444444",
                    "A100": "#ffe57f",
                    "disabledBackground": "#ffd740",
                    "selected": "#2c6b93"
                },
            text: {
                primary: grey[500],
                secondary: grey[500],
                autofill: "red"
            }
        },
    });

    const darkModeTheme = createTheme(getDesignTokens('dark'));

    return (
        <Account>
            <ThemeProvider theme={darkModeTheme}>
                <CssBaseline />
                <Router>
                    <Routes>
                        <Route exact path="*" element={<LoginRouter/>}></Route>
                    </Routes>
                </Router>
            </ThemeProvider>
        </Account>
    )
}

export default App;
