import React from "react";
import axios from "axios";

export default  function useApi (user)
{
    const api = axios.create({
        baseURL: 'https://c45kvtrks6.execute-api.eu-west-1.amazonaws.com/test/',
        timeout: 5000,
        headers: {
            'authorization': user.session.idToken.jwtToken,
        }
    });
    return api
}
