import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import React, { useEffect, useState, Fragment } from "react";
import Typography from "@mui/material/Typography";
import '@fontsource/fjalla-one';
import Button from '@mui/material/Button';
import Signup from "./Signup";
import Login from "./Login"
import Container from '@mui/material/Container';
import '@fontsource-variable/open-sans';
import '@fontsource/archivo-black';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Navigate,
    useLocation
} from "react-router-dom";
import Image from 'mui-image'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import TimelineIcon from '@mui/icons-material/Timeline';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const Logo = () => {

    return (
        <div style={{
            display: "flex",
            lineBreak: "nowrap",
            border: "1px solid #007FFF",
            paddingLeft: "4px",
            paddingRight: "4px",
            userSelect: "none"
        }}>

            <div
                style={{fontSize: "24px", color: "#007FFF", fontFamily: 'Open Sans Variable', fontWeight: 500}}>
                B
            </div>

            <div
                style={{fontSize: "24px", color: "lightgray", fontFamily: 'Open Sans Variable', fontWeight: 500}}>
                ARO
            </div>

            <div
                style={{fontSize: "24px", color: "lightgray", fontFamily: 'Open Sans Variable', fontWeight: 100}}>
                GRAPH
            </div>
        </div>
    )
}

export default function Landingpage(props) {

    const frontPageBackgroundImage = require('../assets/clouds_dark.jpg');

    const divStyle = {
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${frontPageBackgroundImage})`,
        backgroundSize: 'cover',
        overflowY: "scroll",
        overflowX: "hidden",
    }

    return (

        <Box style={divStyle}>
            <Routes>

                <Route exact path="/signin" element={<Login/>}></Route>
                <Route exact path="/signup" element={<Signup/>}></Route>

                <Route exact path="*" element={
                    <>
                        <AppBar position="fixed" style={{height:"80px", backgroundColor:"rgba(0,0,0,0.0)", boxShadow:""}} elevation={0}>
                            <Toolbar variant={"dense"} style={{ padding:"50px", display:"flex", justifyContent:"space-between"}}>

                                <Logo/>

                                <div>
                                    <Button
                                        component={Link}
                                        to="/signin"
                                        style={{color:"white", fontSize:"1.1rem"}}
                                        size="large"
                                        variant="text"
                                    >
                                        Sign In
                                    </Button>
                                </div>
                            </Toolbar>

                        </AppBar>


                        <Grid container style={{height:"100%"}}>

                            <Grid item xs={7}>

                                <div className="Widget" style={{
                                    paddingTop:"200px",
                                    textAlign: "left",
                                    //border:"1px solid red",
                                }}>
                                    <Typography color={"white"} variant="h1" style={{whiteSpace:"nowrap", fontWeight:"bolder", fontSize:"3rem", fontFamily: 'Open Sane Variable, sans-serif'}}>
                                        Stay ahead of changing weather conditions
                                    </Typography>


                                    <List>
                                        <ListItem disableGutters>
                                            <ListItemIcon>
                                                <ThermostatIcon fontSize="large" style={{color:"white"}}/>
                                            </ListItemIcon>
                                            <Typography color={"white"} variant="h5" style={{alignContent:"left", fontFamily: 'Open Sane Variable, sans-serif'}}>
                                                Detailed weather variables
                                            </Typography>
                                        </ListItem>

                                        <ListItem disableGutters>
                                            <ListItemIcon>
                                                <AutoAwesomeIcon fontSize="large" style={{color:"white"}}/>
                                            </ListItemIcon>
                                            <Typography color={"white"} variant="h5" style={{alignContent:"left", fontFamily: 'Open Sane Variable, sans-serif'}}>
                                                16 day forecast
                                            </Typography>
                                        </ListItem>

                                        <ListItem disableGutters>
                                            <ListItemIcon>
                                                <NotificationsActiveIcon fontSize="large" style={{color:"white"}}/>
                                            </ListItemIcon>
                                            <Typography color={"white"} variant="h5" style={{alignContent:"left", fontFamily: 'Open Sane Variable, sans-serif'}}>
                                                Set up your own custom alerts
                                            </Typography>
                                        </ListItem>

                                        <ListItem disableGutters>
                                            <ListItemIcon>
                                                <TimelineIcon fontSize="large" style={{color:"white"}}/>
                                            </ListItemIcon>
                                            <Typography color={"white"} variant="h5" style={{alignContent:"left", fontFamily: 'Open Sane Variable, sans-serif'}}>
                                                40 years of Historical weather conditions
                                            </Typography>
                                        </ListItem>
                                    </List>


                                    {/*
                                        <Button
                                            className="Glass"
                                            component={Link}
                                            style={{fontSize:"1.3rem", width:"400px", margin:"100px", color:"#FF4D4D"}}
                                            size="large"
                                            to="/signup"
                                        >
                                            Create Account
                                        </Button>
                                    */}
                                </div>
                            </Grid>

                            <Grid item xs={5}>
                                {/* <Image src={scrot1} fit="scale-down" height="50vh"/>

                                    <Image src={require('../assets/screenshot2.png')} fit="scale-down" height="50vh"/>
    */}
                            </Grid>


                        </Grid>


                    </>
                }></Route>

            </Routes>
        </Box>

    )
}