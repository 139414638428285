import '../styles/Widget.css';
import { useSelector } from 'react-redux'
import '../styles/Widget.css';
import {
    Tooltip,
    Label,
    AreaChart,
    Area,
    Scatter,
    ScatterChart,
    ComposedChart,
    Line,
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    ResponsiveContainer,
    ReferenceArea
} from 'recharts';
import Grid from '@mui/material/Grid';

import dateFormat, { masks } from "dateformat";
import {useEffect, useState} from "react";
import Constants from "../constants/Constants";

JSON.flatten = function(data) {
    var result = {};
    function recurse (cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            for(var i=0, l=cur.length; i<l; i++)
                recurse(cur[i], prop ? prop+"."+i : ""+i);
            if (l == 0)
                result[prop] = [];
        } else {
            var isEmpty = true;
            for (var p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop+"."+p : p);
            }
            if (isEmpty)
                result[prop] = {};
        }
    }
    recurse(data, "");
    return result;
}


export default function ForecastWeather( {key} ) {
    const data = useSelector(state => state.forecastWeather)
    const [shadeArea, setShadeArea] = useState([])

    console.log("Render forecast with")

    useEffect(() => {
        var alternatingDays = []

        // figure out data.timesereies indices of every other day
        if(data?.timeseries) {

            let d0 = new Date(data.timeseries[0]["time"])
            let i;
            for (i = 0; i < data.timeseries.length; i++) {
                let d = new Date(data.timeseries[i]["time"])
                if (d.getHours() == 0) {
                    break;
                }
            }
            // i is index if first midnight
            let j = i
            while (j < data.timeseries.length-1) {
                if (j - i == 24) {
                    alternatingDays.push([i, j])
                    i = j
                }
                j++;
            }
            alternatingDays = alternatingDays.filter((element, index) => {
                return index % 2 === 0;
            })

            console.log("DEBUG 0", alternatingDays)


            alternatingDays = alternatingDays.map((item, index) => {
                console.log(item[0], item[1])
                return [data.timeseries[item[0]]["time"], data.timeseries[item[1]]["time"]]
            })

            console.log("DEBUG 1", alternatingDays)
            alternatingDays = alternatingDays.map((item, index) => {
                return (
                    <ReferenceArea y1={-10_000} y2={10_000} ifOverflow="visible" x1={item[0]} x2={item[1]} key={item[0]} stroke="lightblue" fillOpacity={0} strokeOpacity={0.1}/>
                )
            })
            console.log("DEBUG 2", alternatingDays)
            setShadeArea(alternatingDays)
        }

    }, [data]);

    if(!data){
        return (
            <></>
        )
    }




    return (
        <div style={{overflow:"hidden", paddingLeft:"0px", display:"flex", flexDirection:"column", alignContent: "right", width :"calc(100vw - 380px)", paddingTop:"30px", height:"calc(100vh - 150px)"}}>

            <ResponsiveContainer width="100%" height="25%">

                <ComposedChart data={data.timeseries}>

                    <Tooltip/>
                    {shadeArea}

                    <Area isAnimationActive={false} stackId={1} type="monotone" dataKey="temperature_2m" stroke={null}/>
                    <Line isAnimationActive={false} stackId={1} type="basisOpen" dataKey="apparent_temperature" dot={false}/>
                    <YAxis>
                        <Label value="Temperature" angle={-90} position="left" offset={-2}/>
                    </YAxis>
                    <XAxis dataKey="time" type="category" hide />

                </ComposedChart>
            </ResponsiveContainer>

            <ResponsiveContainer width="100%" height="25%">

                <ComposedChart data={data.timeseries}>

                    <Tooltip/>
                    {shadeArea}


                    <Area isAnimationActive={false} stackId={1} type="monotone" dataKey="wind_speed_10m" fill="#ffd740" stroke={null}/>
                    <Line isAnimationActive={false} stackId={1} type="basisOpen" dataKey="wind_gusts_10m" stroke="#ffe57f" dot={false}/>
                    <YAxis>
                        <Label value="Wind Speed" angle={-90} position="left" offset={-2}/>
                    </YAxis>
                    <XAxis dataKey="time" type="category" hide />

                </ComposedChart>
            </ResponsiveContainer>

            <ResponsiveContainer width="100%" height="25%" >
                <ComposedChart data={data.timeseries} >
                    <Tooltip/>
                    {shadeArea}
                    <Area isAnimationActive={false} stackId={2} type="monotone" dataKey="snow_depth" dot={false} fill="lightgrey" stroke={null}/>
                    <Line isAnimationActive={false} stackId={1} type="basisOpen" dataKey="snowfall" dot={false} stroke="lightgrey"/>
                    <Line isAnimationActive={false} stackId={1} type="basisOpen" dataKey="rain" dot={false}/>
                    <Line isAnimationActive={false} stackId={1} type="basisOpen" dataKey="showers" strokeDasharray="4 1 1" dot={false}/>


                    <XAxis dataKey="time" type="category" hide />
                    <YAxis dataKey="rain">
                        <Label value="Precipitation" angle={-90} position="left" offset={-2}/>
                    </YAxis>
                </ComposedChart>
            </ResponsiveContainer>


            <ResponsiveContainer width="100%" height="25%">
                <ComposedChart data={data.timeseries} >
                    <Tooltip />
                    {shadeArea}

                    {/*<Area dot={false} type="basisOpen" dataKey="cloud_cover" stroke={null} fill="#2e2e2e"/>*/}
                    <Line isAnimationActive={false} dot={false} type="basisOpen" dataKey="cloud_cover_high" stroke={null} stroke="#ffe57f"/>
                    <Line isAnimationActive={false} dot={false} type="basisOpen" dataKey="cloud_cover_mid" stroke={null} stroke="#ffd740"/>
                    <Line isAnimationActive={false} dot={false} type="basisOpen" dataKey="cloud_cover_low" stroke={null} stroke="#ffa000"/>

                    <YAxis>
                        <Label value="Cloudiness"  angle={-90} position="left" offset={-2}/>
                    </YAxis>

                    <XAxis
                        dataKey="time"
                        type="category"
                        position="top"
                        tickMargin={5}
                        minTickGap={25}
                        tickFormatter={(tick, index)=>{

                            var t= new Date(tick)
                            return dateFormat(t, "ddd d")

                            //console.log("input tick",t.getHours())
                            if(t.getHours()==10){

                            }
                            else{return ""}

                        }}

                    />

                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )
}
