import '../styles/Widget.css';
import { useSelector } from 'react-redux'

import {
    Label,
    AreaChart,
    Area,
    Scatter,
    ScatterChart,
    Line,
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Customized,
    Curve,
    Tooltip
} from 'recharts';


export default function HistoricalWeather( {key} ) {
    const data = [{x:10, y:1}, {x:90, y:1}] // useSelector(state => state.historicalWeather)

    return (
        <div className="Widget">

        </div>
    )
}
