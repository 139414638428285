
module.exports = {
   SET_LOCATIONS : "11",
   SET_CURRENT_WEATHER : "12",
   SET_FORECAST_WEATHER : "13",
   SET_AIR_POLLUTION : "14",
   SET_HISTORICAL_WEATHER : "15",
   SET_CURRENT_LOCATION: "16",

   GET_LOCATIONS : "21",
   GET_CURRENT_WEATHER : "22",
   GET_FORECAST_WEATHER : "23",
   GET_AIR_POLLUTION : "24",
   GET_HISTORICAL_WEATHER : "25",

   ADD_LOCATION : "31"
}
