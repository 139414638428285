import '../styles/Widget.css';
const SunCalc = require('suncalc');


export default function Astron( {key} ) {


  return (
    <div className="Widget">
      Astron

    </div>
  )
}
