import React, {useContext, useState, useEffect} from "react";
import {AccountContext} from "./Account";

const Status = (props) => {
    const [status, setStatus] = useState(false)
    const { getSession } = useContext(AccountContext)

    useEffect(() => {
        getSession()
            .then(session=>{
                console.log(session)
                setStatus(true)
            })
            .catch(err=>{
                console.log("not logged in")
            })
    }, []);

    return (
        <div>{status?"You are logged in":"Please log in"}</div>
    )
}

export default Status