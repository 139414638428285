import '../styles/Widget.css';
import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link, useLocation, useNavigate } from "react-router-dom";
import {useState, useEffect} from "react";

const Constants = require('../constants/Constants')

export default function LocationBar( props ) {
    const locations = useSelector(state => state.locations)
    const currentLocation = useSelector(state=>state.currentLocation)
    const dispatch = useDispatch();

    const setLocation = (location) => {
        dispatch({type:Constants.SET_CURRENT_LOCATION, payload:location})
    }

    return (
        <Box className="Widget" style={{display:"flex", flexDirection:"row", width:"auto"}}>

            {locations?.map(
                (location)=>{
                    return(
                        <Location
                            key={location.id}
                            disabled={props.disabled}
                            selected={location.name==currentLocation.name}
                            location={location}
                            onChange={setLocation}
                        />
                    )
                }
            ) }
        </Box>
    )
}

function Location(props){
    const path = useLocation() // router nav location, not geo
    const navigate = useNavigate()
    const [currentView, setCurrentView] = useState(null)

    useEffect(() => {
        // Component Mount
        setCurrentView(path)
    }, []);

    useEffect(() => {
        if(path.pathname!=="/addlocation"){
            setCurrentView(path)
        }
    }, [path]);

    return (
        <div key={props.location.name} style={{margin: "2px"}}>
            <Button
                disabled={props.disabled}
                variant={props.selected?"outlined":"text"}
                onClick={()=>{
                    console.log("Location selected", props.location, "path", path)
                    props.onChange(props.location)
                    navigate(currentView)
                }

                }
            >
                {props.location.name}
            </Button>
        </div>
    )
}