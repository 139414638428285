import { CognitoUserPool} from "amazon-cognito-identity-js";

const poolData = {
    UserPoolId: "eu-west-1_ioNWRtB2a",
    ClientId: "16i3i81e4hpm9apq83kdta6n05"
}

export default new CognitoUserPool(poolData)

// Karl
// foo@bar.com
// Foobar12344!