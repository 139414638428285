const Constants = require('../constants/Constants')


const initialState = {
  locations: [],
  currentLocation: {name: undefined, lat: undefined, lon: undefined, tz: undefined},
  currentWeather: undefined,
  forecastWeather: undefined,
  airPollution: undefined,
  historicalWeather: undefined,
};

export function reducer (state=initialState, action) {

//  const payload =  action.payload ? JSON.parse(action.payload): undefined;

  switch (action.type) {
    case Constants.SET_LOCATIONS:
      return {...state, locations: action.payload}
    case Constants.SET_CURRENT_LOCATION:
      return {...state, currentLocation: action.payload}
    case Constants.SET_CURRENT_WEATHER:
      return {...state, currentWeather: action.payload}
    case Constants.SET_FORECAST_WEATHER:
      return {...state, forecastWeather: action.payload}
    case Constants.SET_AIR_POLLUTION:
      return {...state, airPollution: action.payload}
    case Constants.SET_HISTORICAL_WEATHER:
      return {...state, historicalWeather: action.payload}
    default:
      return state
  }
}
