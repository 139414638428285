import '../styles/Widget.css';


export default function Journal( {key} ) {
  return (
    <div className="Widget">
      Journal
    </div>
  )
}
