import React, {useEffect, useState, Fragment, useContext} from "react";
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import LocationBar from "./Location.js"
import { AddLocation, LocationInput } from "./AddLocation"
import CurrentWeather from './CurrentWeather'
import HistoricalWeather from './HistoricalWeather'
import '../styles/Layout.css';
import ForecastWeather from "./ForecastWeather";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MaximizeIcon from '@mui/icons-material/Maximize';
import MinimizeIcon from '@mui/icons-material/Minimize';
import Divider from '@mui/material/Divider';
import AddLocationIcon from "@mui/icons-material/AddLocation";
import {
    Routes,
    Route,
    Link,
    Navigate,
    useNavigate,
    useLocation
} from "react-router-dom";
import CustomDataEntry from "./CustomDataEntry";
import WeatherAlert from "./Alerts";
import Journal from "./Journal";
import Button from "@mui/material/Button";
import useApi from "../hooks/useApi"
import Constants from "../constants/Constants";
import Timezones from "../constants/Timezones"
import { useSelector, useDispatch } from 'react-redux'

const drawerWidth = 360;

function EquinoxToolbar(props){
    const navigate = useNavigate()

    return(
        <AppBar className="AppBar" position="fixed"  sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar sx={{background:"#121212", display:"flex", flexDirection:"row", justifyContent:"space-between"}}>

                <Box className="NotDraggable"  sx={{ display: 'flex'}} >

                    <LocationBar/>
                    <IconButton component={Link} to="/addlocation" aria-label="Add location">
                        <AddLocationIcon user={props.user}/>
                    </IconButton>

                </Box>
                {props.desktop &&
                    <Box className="NotDraggable" >
                        <IconButton varient="square" aria-label="Minimize" style={{ borderRadius:"0px"}}>
                            <MinimizeIcon style={{color:"grey"}}/>
                        </IconButton>

                        <IconButton aria-label="Maximise" style={{ borderRadius:"0px"}}>
                            <MaximizeIcon style={{color:"grey"}}/>
                        </IconButton>

                        <IconButton className="CloseButton" aria-label="Close" style={{ borderRadius:"0px"}}>
                            <CloseIcon style={{color:"grey"}}/>
                        </IconButton>
                    </Box>
                }

                <Button onClick={()=>{
                    props.logout()
                    navigate("/")
                }}>
                    Sign out
                </Button>

            </Toolbar>

        </AppBar>
    )
}
function Sidedrawer(props){
    return(
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <CurrentWeather/>
        </Drawer>
    )

}

function MainView(props){
    const location = useLocation()
    console.log("render MainView")
    return(
        <div  style={{padding: "10px", left:"360px", right:"0px", top:"100px", bottom:"10px", position:"absolute"}}>
            <Routes>
                <Route exact path="/forecast"  element={<ForecastWeather/>}></Route>
                <Route exact path="/historical"  element={<HistoricalWeather/>}></Route>
                <Route exact path="/custom" element={<CustomDataEntry/>}></Route>
                <Route exact path="/alerts" element={<WeatherAlert/>}></Route>
                <Route exact path="/journal" element={<Journal/>}></Route>
            </Routes>
        </div>
    )
}

function ViewTabs(props){
    const location = useLocation()
    console.log("active tab:", location.pathname)
    return(
        <>
            <Tabs value={location.pathname} aria-label="main blotter tab" sx={{paddingTop:"10px"}}>
                <Tab label="Forecast" component={Link} value="/forecast" to="/forecast"  sx={{fontSize: "small"}} />
                <Tab label="Historical" component={Link} value="/historical" to="/historical"  sx={{fontSize: "small"}} />
                <Tab label="Custom data" component={Link}  value="/custom" to="/custom"  sx={{fontSize: "small"}} />
                <Tab label="Alerts"component={Link} to="/alerts" value="/alerts"  sx={{fontSize: "small"}} />
                <Tab label="Journal"component={Link} to="/journal" value="/journal"   sx={{fontSize: "small"}} />
            </Tabs>
            <Divider/>
        </>
    )
}

function useCurrentWeather(api){
    const location = useSelector(state => state.currentLocation)
    const dispatch = useDispatch()

    api.get(
        "/currentweather",
        {"params": {location: location.id}}
    )
        .then(
            (response)=>{
                console.log("Got current weather", response)
                dispatch({
                        type: Constants.SET_CURRENT_WEATHER,
                        payload: {...response?.data?.weather, units:response?.data?.units}
                    })
            }
        )
        .catch(
            (error)=>{
                console.log("Failed to get current weather", error)
            }
        )
}

function useForecastWeather(api){
    const location = useSelector(state => state.currentLocation)
    const dispatch = useDispatch()

    api.get(
        "/getforecast",
        {"params": {location: location.id}}
    )
        .then(
            (response)=>{
                console.log("Got forecast weather", response)
                dispatch({
                        type: Constants.SET_FORECAST_WEATHER,
                        payload: {timeseries: response?.data?.weather, units:response?.data?.units}
                    })
            }
        )
        .catch(
            (error)=>{
                console.log("Failed to get forecast weather", error)
            }
        )
}

export function DesktopLayout(props) {
    const dispatch = useDispatch()
    const api = useApi(props.user)
    useCurrentWeather(api)
    useForecastWeather(api)

    console.log("DesktopLayout props.user=", props.user)

    useEffect(() => {
        api.get("getlocations")
            .then((response)=> {
                    console.log("/getlocations response:", response)
                    dispatch({
                        type: Constants.SET_LOCATIONS,
                        payload: response.data
                    })
                }
            )
            .catch((error)=>{
                console.log("/getlocations failed:", error)
            })

    }, []);

    return (
        <>
            <EquinoxToolbar logout={props.user.sessionLogout}/>

            <Routes>
                <Route exact path="/addlocation" element={<AddLocation user={props.user}/>}></Route>
                <Route path="/" element={<Navigate to="/forecast"/>}></Route>

                <Route path="/*" element={
                    <Box sx={{ display: 'flex', overflow:""}}>
                        <CssBaseline />

                        <Sidedrawer/>
                        <Box
                            component="main"
                            sx={{flexGrow: 1, p: 3, paddingTop:"50px", width:"100%"}}
                        >
                            <ViewTabs/>
                            <MainView/>
                        </Box>
                    </Box>
                }
                ></Route>
            </Routes>


        </>
    );
};

export default DesktopLayout
