import React, {createContext} from "react";
import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import Pool from "../UserPool";

const AccountContext = createContext()
const Account = (props) => {

    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession((err, session) => {
                    if(err) {
                        reject()
                    }else{
                        resolve(session)
                    }
                })
            } else {
                reject();
            }
        })
    }

    const authenticate = async (Username, Password) => {

        return await new Promise((resolve, reject)=>{
            const user = new CognitoUser({Username, Pool});

            const authDetails = new AuthenticationDetails({
                Username,
                Password
            });

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    console.log("Login success!", data)
                    resolve(data)
                },
                onFailure: (err) => {
                    console.error("Login fail", err)
                    reject(err)
                },
                newPasswordRequired: (data) => {
                    console.log("newPasswordRequired", data)
                    resolve(data)
                }
            })
        })
    }

    const logout = () => {
        const user = Pool.getCurrentUser()
        if (user) {
            user.signOut()
        }
    }

    return (
        <AccountContext.Provider value={{authenticate, getSession, logout}}>
            {props.children}
        </AccountContext.Provider>
    )
}

export {Account, AccountContext}

// kms.warwick@gmail.com
// Foo_bar_1234!