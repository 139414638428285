import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


export function Measurement(props, content) {

    return (
        <Card variant="plain" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>

            <Typography variant="caption" align="left">
                {props.memo}
            </Typography>

            <Typography variant="h5" align="right" >
                {props.value}
                {props.unit}
            </Typography>

        </Card>
    )
}

export function MeasurementGroup(props, content) {
    return (
        <Card variant="outlined" sx={{margin: "5px", padding: "5px", width:"150px", height:"100%"}}>
            <Typography variant="caption" align="left">
                {props.label}
            </Typography>
            {props.children}
        </Card>
    )
}

